import { LOCAL_USER } from "../constants"

interface IRequestPostOrPut {
    url: string
    body: Record<string, any>
    auth?: boolean
    method?: 'POST' | 'PUT',
    typeRes?: 'text' | 'json' | 'blob'
}

export async function requestPostOrPut({ url, body, auth = false, method = "POST", typeRes = 'json' }: IRequestPostOrPut) {

    let authToken = localStorage.getItem(LOCAL_USER)

    let headers = {
        'Content-Type': 'application/json'
    }

    if (auth && !authToken) {
        throw new Error("Вы не авторизованы")
    } else if (auth) {
        headers = Object.assign({}, headers, { 'Authorization': 'Bearer ' + authToken })
    }

    const bodyJson = JSON.stringify(body)
    
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
        method,
        body: bodyJson,
        headers
    })

    if (res.ok) {
        switch(typeRes) {
            case 'text': {
                return await res.text()
            }
            case 'json': {
                return await res.json()
            }
            case 'blob': {
                const { name, token } = await res.json();

                // Create a Blob with the token content
                const blob = new Blob([token], { type: 'application/octet-stream' });

                // Create a link element and trigger a click on it to initiate the download
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${name}.lickey`;
                link.click();

                // Clean up: remove the link and revoke the blob URL
                window.URL.revokeObjectURL(link.href);

                return
            }
        }
    }

    const data = await res.json()
    
    throw new Error(data.message)
}