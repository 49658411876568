import React from "react";
import SmallLoader from '../smallLoader'

interface IButton {
    text: string
    loading: boolean
    styleButton?: 'btn-primary' | 
                'btn-secondary' |
                'btn-success' | 
                'btn-info' | 
                'btn-warning' | 
                'btn-danger' | 
                'btn-light' |
                'btn-dark' |
                'btn-link'
    type?: "button" | "submit" | "reset" | undefined
    event: React.MouseEventHandler
    disabled?: boolean
}

const Button = ({ text, loading, disabled = false, styleButton = "btn-primary", type = "button", event }: IButton) => {

    return (
        <button onClick={event} type={type} className={`btn ${styleButton} ${disabled || loading ? "disabled" : ""}`}>
          <span>{text}</span>  
          {loading && <SmallLoader /> }
          
        </button>
    )
}

export default Button