import React from "react"
import { useInput } from "../../hooks/useInput"
import { requestPostOrPut } from "../../requests"
import { postURL } from "../../requests/urlRequests"
import Auth from './auth'
import { useActions } from "../../hooks/useActions"
import { LOCAL_USER } from "../../constants"

const AuthWrapper = () => {

    const { 
        actionChangeLoadingUser, 
        actionChangeErrorUser, 
        actionChangeSuccessUser,
        actionLogin
    } = useActions()

    const loginInput = useInput("", {
        lengthMin: {
            value: 1,
            message: "Поле должно быть заполнено"
        }
    })

    const passwordInput = useInput("", {
        lengthMax: { value: 15, message: "Максимальная длина 15 символов" },
        lengthMin: { value: 3, message: "Минимальная длина 3 символа" }
    })

    const clearEverything = () => {
        loginInput.clearValue()
        passwordInput.clearValue()
        actionChangeLoadingUser(false)
        setTimeout(() => {
            actionChangeErrorUser(null)
            actionChangeSuccessUser(null)
        }, 3000)
    }

    const submitLogin = async (event: any) => {
        event.preventDefault()
        actionChangeLoadingUser(true)

        const body = {
            login: loginInput.value,
            password: passwordInput.value,
        }

        try {
            const data = await requestPostOrPut({ url: postURL.LOGIN, body})

            if (!data.message) {
                
                actionLogin(data.user)
                localStorage.setItem(LOCAL_USER, data.token)
                actionChangeSuccessUser(data.message)
            } else {
                actionChangeErrorUser(data.message)
            }

        } catch(err: any) {
            actionChangeErrorUser(err)
        }

        clearEverything()
    }

    const buttonIsDisabled = !loginInput.errors && !passwordInput.errors

    return <Auth loginInput={loginInput}
        passwordInput={passwordInput}
        buttonIsDisabled={buttonIsDisabled}
        submit={submitLogin}
    />
}

export default AuthWrapper