import React from "react"
import './index.css'

const SmallLoader = () => {
    return (
        <div className="loadingio-spinner-rolling-ko9vl8eolu">
            <div className="ldio-aemh6lihbnr">
                <div>
                </div>
            </div>
        </div>
    )
}

export default SmallLoader