import { IUser } from '../../../types/user/user.type'

export enum TypeActionUser {
    LOGIN = "LOGIN",
    ADD_ERROR_LOGIN = "ADD_ERROR_LOGIN",
    ADD_SUCCESS_LOGIN = "ADD_SUCCESS_LOGIN",
    CHANGE_LOADING = "CHANGE_LOADING",
    ADD_USER = "ADD_USER"
}

interface ActionLogin {
    type: TypeActionUser.LOGIN
    payload: IUser
}

interface ActionChangeLoading {
    type: TypeActionUser.CHANGE_LOADING,
    payload: boolean
}

interface ActionAddErrorLogin {
    type: TypeActionUser.ADD_ERROR_LOGIN,
    payload: string | null
}

interface ActionAddSuccessLogin {
    type: TypeActionUser.ADD_SUCCESS_LOGIN,
    payload: string | null
}

interface ActionAddUser {
    type: TypeActionUser.ADD_USER,
    payload: IUser
}


export type ActionUser = 
    ActionLogin | 
    ActionAddErrorLogin | 
    ActionAddSuccessLogin | 
    ActionChangeLoading |
    ActionAddUser

export interface IStateUser {
    user: IUser
    isAuth: boolean
    errorLogin: string | null | undefined
    successLogin: string | null | undefined
    loading: boolean
}