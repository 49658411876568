import { ActionLicense, IStateLicense,TypeActionLicense } from './type'

const defaultState: IStateLicense = {
    errorLicense: null,
    loadingLicense: false,
    successLicense: null
}

export const licenseReducer = (state = defaultState, action: ActionLicense): IStateLicense => {
    switch(action.type) {

        case TypeActionLicense.ADD_ERROR_LICENSE: {
            return {
                ...state,
                errorLicense: action.payload
            }
        }

        case TypeActionLicense.ADD_SUCCESS_LICENSE: {
            return {
                ...state,
                successLicense: action.payload
            }
        }

        case TypeActionLicense.CHANGE_LOADING_LICENSE: {
            return {
                ...state,
                loadingLicense: action.payload
            }
        }

        default: {
            return state
        }
    }
}