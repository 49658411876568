import { ActionUser, TypeActionUser } from "../reducers/userReducer/type"
import { IUser } from '../../types/user/user.type'

export const actionChangeLoadingUser = (payload: boolean): ActionUser => {
    return {
        type: TypeActionUser.CHANGE_LOADING,
        payload
    }
}

export const actionChangeErrorUser = (payload: string | null): ActionUser => {
    return {
        type: TypeActionUser.ADD_ERROR_LOGIN,
        payload
    }
}

export const actionChangeSuccessUser = (payload: string | null): ActionUser => {
    return {
        type: TypeActionUser.ADD_SUCCESS_LOGIN,
        payload
    }
}

export const actionLogin = (payload: IUser): ActionUser => {
    return {
        type: TypeActionUser.LOGIN,
        payload
    }
}

export const actionAddUser = (payload: IUser): ActionUser => {
    return {
        type: TypeActionUser.ADD_USER,
        payload
    }
}
