import React from 'react';
import './App.css';
import Auth from './components/auth/'
import { useTypedSelector } from './hooks/useTypedSelector';
import License from './components/license';


function App() {
  const user = useTypedSelector(state => state.user)
  return (
    <div className="App">
      {
        user.user && user.user.id ?
        <License />
        :
        <Auth />
      }
    </div>
  );
}

export default App;
