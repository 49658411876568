import { useActions } from '../../hooks/useActions'
import { useInput } from '../../hooks/useInput'
import { requestPostOrPut } from '../../requests'
import { postURL } from '../../requests/urlRequests'
import License from './license'

const LicenseWrapper = () => {
    const { actionChangeErrorLicense, actionChangeLoadingLicense } = useActions()

    const nameInput = useInput("", {
        lengthMin: {
            value: 1,
            message: "Поле должно быть заполнено"
        }
    })

    const typeInput = useInput("online", {})

    const dateInput = useInput("", {
        isDate: {
            message: "Должно быть датой"
        }
    })

    const adminAndDeveloperInput = useInput("1", {
        isAplha: {
            message: "Должно быть числом"
        }
    })

    const analystAndViewerInput = useInput("1", {
        isAplha: {
            message: "Должно быть числом"
        }
    })

    const clearEverything = () => {
        nameInput.clearValue()
        dateInput.clearValue()
        adminAndDeveloperInput.clearValue()
        analystAndViewerInput.clearValue()
        actionChangeLoadingLicense(false)
        setTimeout(() => {
            actionChangeErrorLicense(null)
            // actionChangeSuccessLicense(null)
        }, 3000)
    }

    const submitCreateLicense = async (event: any) => {
        event.preventDefault()

        const body = {
            companyName: nameInput.value,
            type: typeInput.value,
            expires: dateInput.value,
            meta: [
                {
                    role: 'AD',
                    count: Number(adminAndDeveloperInput.value) || 0
                },
                {
                    role: 'AV',
                    count: Number(analystAndViewerInput.value) || 0
                },
            ]
        }

        try {
            await requestPostOrPut({ url: postURL.CREATE_LICENSE, body, auth: true, typeRes: 'blob'})
        } catch(err: any) {
            actionChangeErrorLicense(err.message)
        }

    

        clearEverything()
    }

    const buttonIsDisabled = !nameInput.errors && 
                                !dateInput.errors && 
                                !adminAndDeveloperInput.errors &&
                                !analystAndViewerInput.errors

    return <License 
            nameInput={nameInput}
            typeInput={typeInput}
            dateInput={dateInput}
            adminAndDeveloperInput={adminAndDeveloperInput}
            analystAndViewerInput={analystAndViewerInput}
            buttonIsDisabled={buttonIsDisabled}
            submit={submitCreateLicense}
        />
}

export default LicenseWrapper