export enum TypeActionLicense {
    ADD_ERROR_LICENSE = "ADD_ERROR_LICENSE",
    ADD_SUCCESS_LICENSE = "ADD_SUCCESS_LICENSE",
    CHANGE_LOADING_LICENSE = "CHANGE_LOADING_LICENSE",
}


interface ActionChangeLoadingLicense {
    type: TypeActionLicense.CHANGE_LOADING_LICENSE,
    payload: boolean
}

interface ActionAddErrorLicense {
    type: TypeActionLicense.ADD_ERROR_LICENSE,
    payload: string | null
}

interface ActionAddSuccessLicense {
    type: TypeActionLicense.ADD_SUCCESS_LICENSE,
    payload: string | null
}


export type ActionLicense = 
    ActionChangeLoadingLicense | 
    ActionAddErrorLicense |
    ActionAddSuccessLicense 

export interface IStateLicense {
    errorLicense: string | null | undefined
    successLicense: string | null | undefined
    loadingLicense: boolean
}