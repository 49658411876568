import React from "react";
import Input from "../input";
import Button from "../button";
import { useInput } from '../../hooks/useInput'
import { useTypedSelector } from "../../hooks/useTypedSelector";
// import { useSelector } from "react-redux"
import './index.css'

interface IAuth {
    loginInput: ReturnType<typeof useInput>
    passwordInput: ReturnType<typeof useInput>
    buttonIsDisabled: boolean
    submit: any
}

const Auth = ({ loginInput, passwordInput, buttonIsDisabled, submit }: IAuth) => {

    const { successLogin, errorLogin, loading } = useTypedSelector(state => state.user)

    return (
        <div className="form page">
            <h1>Авторизация</h1>
            <Input nameLabel="Ваш логин" placeholder="Логин"
                value={loginInput.value}
                validErrorMessage={loginInput.errors}
                blur={loginInput.handlerChangeBlur}
                change={loginInput.changeHadnlerInput}
                isClearBlur={loginInput.isClearBlur}
            />
            <Input nameLabel="Ваш пароль" typeInput="password" placeholder="Пароль"
                value={passwordInput.value}
                validErrorMessage={passwordInput.errors}
                blur={passwordInput.handlerChangeBlur}
                change={passwordInput.changeHadnlerInput}
                isClearBlur={passwordInput.isClearBlur}
            />

            {successLogin && <div className="message message--success">{successLogin}</div>}
            {errorLogin && <div className="message message--error">{errorLogin}</div>}

            <div>
                <Button text="Войти" styleButton="btn-success" event={submit} disabled={!buttonIsDisabled} loading={loading} />
            </div>
        </div>
    )
}

export default Auth