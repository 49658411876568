import React from 'react'
import './index.css'
import Button from '../button'
import { useInput } from '../../hooks/useInput'
import Input from "../input";
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface ILicense {
    nameInput: ReturnType<typeof useInput>
    typeInput: ReturnType<typeof useInput>
    dateInput: ReturnType<typeof useInput>
    adminAndDeveloperInput: ReturnType<typeof useInput>
    analystAndViewerInput: ReturnType<typeof useInput>
    buttonIsDisabled: boolean
    submit: any,
}

const License = ({
    nameInput,
    dateInput,
    adminAndDeveloperInput,
    analystAndViewerInput,
    typeInput,
    buttonIsDisabled,
    submit,
}: ILicense) => {
    const { loadingLicense, errorLicense } = useTypedSelector(state => state.license)

    return (
        <div>
            <h1>Создание лицензии</h1>

            <Input nameLabel="Название компании" placeholder="Winsolution"
                value={nameInput.value}
                validErrorMessage={nameInput.errors}
                blur={nameInput.handlerChangeBlur}
                change={nameInput.changeHadnlerInput}
                isClearBlur={nameInput.isClearBlur}
            />

            <div className="form-group" style={{ maxWidth: '277px', margin: '0 auto' }}>
                <label htmlFor="exampleSelect1" className="form-label mt-4">Тип лизензии</label>
                <select className="form-select" id="exampleSelect1" onChange={typeInput.changeHadnlerInput as any} value={typeInput.value}>
                    <option>online</option>
                    <option>offline</option>
                </select>
            </div>

            <Input nameLabel="Дата конца лицензии" placeholder="2023-07-28"
                value={dateInput.value}
                validErrorMessage={dateInput.errors}
                blur={dateInput.handlerChangeBlur}
                change={dateInput.changeHadnlerInput}
                isClearBlur={dateInput.isClearBlur}
            />

            <div className='mt-5 mb-5'>
                <h3>Количество доступных ролей</h3>

                <div className='jcsb mt-5'>
                    <h5 className='col-6'>Admin/Developer</h5>
                    <Input
                        value={adminAndDeveloperInput.value}
                        validErrorMessage={adminAndDeveloperInput.errors}
                        blur={adminAndDeveloperInput.handlerChangeBlur}
                        change={adminAndDeveloperInput.changeHadnlerInput}
                        isClearBlur={adminAndDeveloperInput.isClearBlur}
                    />
                </div>

                <div className='jcsb mt-2'>
                    <h5 className='col-6'>Analyst/Viewer</h5>
                    <Input
                        value={analystAndViewerInput.value}
                        validErrorMessage={analystAndViewerInput.errors}
                        blur={analystAndViewerInput.handlerChangeBlur}
                        change={analystAndViewerInput.changeHadnlerInput}
                        isClearBlur={analystAndViewerInput.isClearBlur}
                    />
                </div>
            </div>

            {errorLicense && <div className="message message--error">{errorLicense}</div>}

            <div>
                <Button text="Создать" styleButton="btn-success" event={submit} disabled={!buttonIsDisabled} loading={loadingLicense} />
            </div>
        </div>
    )
}

export default License