import { IStateUser, ActionUser, TypeActionUser } from "./type";

const defaultState: IStateUser = {
    user: {
        id: null,
        login: null
    },
    isAuth: false,
    errorLogin: null,
    successLogin: null,
    loading: false
}

export const userReducer = (state = defaultState, action: ActionUser): IStateUser => {
    switch(action.type) {

        case TypeActionUser.LOGIN: {
            return {
                ...state,
                user: action.payload,
                errorLogin: null,
                isAuth: true
            }
        }

        case TypeActionUser.ADD_SUCCESS_LOGIN: {
            return {
                ...state,
                successLogin: action.payload
            }
        }

        case TypeActionUser.ADD_ERROR_LOGIN: {
            return {
                ...state,
                errorLogin: action.payload
            }
        }

        case TypeActionUser.ADD_USER: {
            return {
                ...state,
                user: action.payload
            }
        }

        case TypeActionUser.CHANGE_LOADING: {
            return {
                ...state,
                loading: action.payload
            }
        }

        default: {
            return state
        }
    }
}