import { ActionLicense,TypeActionLicense } from '../reducers/licenseReducer/type'


export const actionChangeLoadingLicense = (payload: boolean): ActionLicense => {
    return {
        type: TypeActionLicense.CHANGE_LOADING_LICENSE,
        payload
    }
}

export const actionChangeErrorLicense = (payload: string | null): ActionLicense => {
    return {
        type: TypeActionLicense.ADD_ERROR_LICENSE,
        payload
    }
}

export const actionChangeSuccessLicense = (payload: string | null): ActionLicense => {
    return {
        type: TypeActionLicense.ADD_SUCCESS_LICENSE,
        payload
    }
}